@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap');


*{
  font-family: 'Noto Sans KR', sans-serif;
}

.font-bold{
  font-weight:700;
}
.font-semibold{
  font-weight:500;
}